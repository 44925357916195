<template>
    <div>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile>
                <v-card-text>
                    <v-container>
                        <v-img contain max-height="150" :src="selectedSrc"></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => {
                        imgdialog = false;
                    }
                        ">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create Image Dialog -->

        <v-dialog v-model="imageDialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-plus </v-icon>
                    Create Template
                </v-btn>
            </template>

            <v-col class="flex flex-end">
                <v-icon dark medium class="mr-1" @click="imageDialog = false">
                    mdi-close
                </v-icon>
            </v-col>

            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{ imageFormIcon }}</v-icon>{{
                        imageFormTitle
                    }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                                <v-img contain max-height="150" :src="selectedImage"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input label="Template Image" outlined dense @change="getImage()"
                                    v-model="editedImageItem.image_url" prepend-icon=""
                                    append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field outlined dense v-model="editedImageItem.name" :error-messages="nameErrors"
                                    required @input="
                                        editedImageIndex == -1 ? $v.editedImageItem.name.$touch() : null
                                        " @blur="editedImageIndex == -1 ? $v.editedImageItem.name.$touch() : null"
                                    label="Name" append-icon="mdi-rename-box"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field outlined dense v-model="editedImageItem.category" :error-messages="nameErrors"
                                    required @input="
                                        editedImageIndex == -1 ? $v.editedImageItem.category.$touch() : null
                                        " @blur="editedImageIndex == -1 ? $v.editedImageItem.category.$touch() : null"
                                    label="Category" append-icon="mdi-rename-box"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select :items="[
                                    'Hindi',
                                    'Marathi',
                                    'Gujarati',
                                    'English',
                                    'Punjabi',
                                    'Bengali',
                                    'Telugu',
                                    'Tamil',
                                    'Malayalam',
                                    'Manipuri'
                                ]" label="Language" outlined dense required
                                    @input="$v.editedImageItem.language.$touch()"
                                    @blur="$v.editedImageItem.language.$touch()" v-model="editedImageItem.language"
                                    prepend-icon="" append-icon="mdi-access-point"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select dense outlined v-model="editedImageItem.logo_position" :items="[
                                    'Invisible',
                                    'Bottom-Left',
                                    'Bottom-Right',
                                ]
                                    " label="Logo position" :error-messages="logo_positionErrors" required @input="
        editedImageIndex == -1
            ? $v.editedImageItem.logo_position.$touch()
            : null
        " @blur="
        editedImageIndex == -1
            ? $v.editedImageItem.logo_position.$touch()
            : null
        " append-icon="mdi-arrow-decision"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select dense outlined v-model="editedImageItem.profile_visible" :items="['Yes', 'No']"
                                    label="Profile Visibility" :error-messages="dp_visibilityErrors" required @input="
                                        editedImageIndex == -1
                                            ? $v.editedImageItem.profile_visible_location.$touch()
                                            : null
                                        " @blur="
        editedImageIndex == -1
            ? $v.editedImageItem.profile_visible_location.$touch()
            : null
        " append-icon="mdi-eye"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select dense outlined v-show="editedImageItem.profile_visible == 'Yes'"
                                    v-model="editedImageItem.profile_visible_location"
                                    :items="['Bottom-Left', 'Bottom-Right']" label="Profile position"
                                    :error-messages="dp_positionErrors" required @input="
                                        editedImageIndex == -1
                                            ? $v.editedImageItem.profile_visible_location.$touch()
                                            : null
                                        " @blur="
        editedImageIndex == -1
            ? $v.editedImageItem.profile_visible_location.$touch()
            : null
        " append-icon="mdi-arrow-decision"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select dense outlined v-model="editedImageItem.status" :items="['Active', 'Inactive']"
                                    label="Status" :error-messages="statusErrors" required @input="
                                        editedImageIndex == -1 ? $v.editedImageItem.status.$touch() : null
                                        " @blur="
        editedImageIndex == -1 ? $v.editedImageItem.status.$touch() : null
        " append-icon="mdi-list-status"></v-select>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field outlined dense type="datetime-local" v-model="editedImageItem.date" required
                                    label="Date"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <!-- make first letter upper case -->
                                <v-select dense outlined v-model="editedImageItem.image_type"
                                    :items="['All', 'Individual', 'Business', 'Political', 'NGO']" label="Type"
                                    :error-messages="typeErrors" required @input="
                                        editedImageIndex == -1 ? $v.editedImageItem.image_type.$touch() : null
                                        "
                                    @blur="editedImageIndex == -1 ? $v.editedImageItem.image_type.$touch() : null"
                                    append-icon="mdi-briefcase"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-show="editedImageItem.image_type == 'Political'" outlined :multiple="true" dense
                                    :items="politicals" v-model="editedImageItem.political_party"
                                    :error-messages="political_partyErrors" required
                                    @input="editedIndex == -1 ? $v.editedImageItem.political_party.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedImageItem.political_party.$touch() : null"
                                    label="Political party" append-icon="mdi-gamepad-circle"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select outlined :multiple="true" dense :items="states" v-model="editedImageItem.state"
                                    required @input="editedIndex == -1 ? $v.editedImageItem.state.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedImageItem.state.$touch() : null" label="State"
                                    append-icon="mdi-gamepad-circle"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <label>Show Protocol</label>
                                <v-radio-group required v-model="editedImageItem.show_protocol" row>
                                    <v-radio label="Yes" :value="true"></v-radio>
                                    <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{ error }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="closeImage"> Cancel </v-btn>
                    <v-btn color="success" @click="saveImage"> Save </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Template By Date-->
        <!-- add padding -->
        <v-dialog v-model="dateDeleteDialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2 ml-3" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-plus </v-icon>
                    Delete Template By Date
                </v-btn>
            </template>
            <v-card>
                <v-col class="flex flex-end">
                    <v-icon black medium class="mr-1" @click="dateDeleteDialog = false">
                        mdi-close
                    </v-icon>
                </v-col>

                <v-card-title class="error white--text">
                    <v-icon color="white" class="mr-2 ">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title>


                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field outlined dense type="date" class="mt-4" v-model="startDate" required
                                    label="Start Date"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field outlined dense type="date" v-model="endDate" required
                                    label="End Date"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dateDeleteDialog = false">Cancel </v-btn>
                    <v-btn color="success" @click="dateDeleteItems">OK </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>


        </v-dialog>


        <v-dialog v-model="imageDialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeImageDelete">Cancel </v-btn>
                    <v-btn color="success" @click="deleteImageItemConfirm">OK </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table :headers="headers" :items="images" sort-by="calories" class="elevation-3">
            <template v-slot:item.image_url="{ item }">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.image_url)">
                    <v-img contain v-if="item.image_url" :src="item.image_url"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.party_name="{ item }">
                <span>{{ item.frame_type === 'NGO' ? '' : item.party_name }}</span>
            </template>
            <template v-slot:item.premium="{ item }">
                <p>{{ item.premium.toString() === 'true' ? 'Yes' : 'No' }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 warning--text" @click="editImageItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteImageItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
                <v-icon v-else x-large color="grey lighten-1">
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
//import ReFileInputCard from "../components/form/ReFileInputCard";

export default {
    name: "Template",
    mixins: [validationMixin],
    data: () => ({
        re: 0,
        imgdialog: false,
        selectedSrc: null,
        headers: [
            {
                text: "Template Image",
                value: "image_url",
                sortable: false,
                align: "start",
            },
            { text: "Date", value: "date", sortable: true },
            { text: "Download Count", value: "download_count", sortable: false },
            { text: "Name", value: "name", sortable: false },
            { text: "Status", value: "status", sortable: false },
            { text: "Actions", value: "actions", sortable: false },
        ],

        images: [],
        apiUrl: process.env.VUE_APP_API_URL,
        loading: true,
        imageDialog: false,
        folderDialog: false,
        imageDialogDelete: false,
        folderDialogDelete: false,
        dateDeleteDialog: false,
        startDate: null,
        endDate: null,
        error: "",
        states: "",
        politicals: [],
        editedImageIndex: -1,
        selectedImageUrl: null,
        editedImageItem: {
            image_url: null,
            name: "",
            logo_visible: "",
            logo_position: "",
            profile_visible: "",
            profile_visible_location: "",
            status: "",
            image_type: "",
            date: '',
            state: [],
            party: '',
            category: '',
            premium: false,
            language: '',
            show_protocol: true,
        },
        defaultImageItem: {
            image_url: null,
            name: "",
            logo_position: "",
            profile_visible: "",
            profile_visible_location: "",
            status: "",
            image_type: "",
            date: '',
            state: [],
            party: '',
            category: '',
            premium: false,
            language: '',
            show_protocol: true,
        },
        selectedImage: null,
    }),
    created() {
        this.getImages();
        this.getAllPoliticals();
        this.getAllStates();
    },
    methods: {
        dateDeleteItems() {

            if (this.startDate == null || this.endDate == null) {
                alert("Please enter a start and end date");
                return;
            }

            this.$Axios
                .post('/api/v1/image/dateDelete', {
                    start_date: this.startDate,
                    end_date: this.endDate,
                    image_type: "template"
                })
                .then(() => {
                    this.getImages();
                })
                .catch((e) => {
                    console.log(e);
                })
            this.startDate = null
            this.endDate = null
            this.dateDeleteDialog = false;
        },
        openImage(src) {
            this.selectedSrc = src;
            this.imgdialog = true;
        },
        editImageItem(item) {
            this.selectedImage = item.image_url;
            this.editedImageIndex = this.images.indexOf(item);
            this.editedImageItem = Object.assign({}, item);
            this.editedImageItem.status = item.status == true ? "Active" : "Inactive";
            this.editedImageItem.image_url = null;
            this.editedImageItem.profile_visible = item.profile_visible == true ? "Yes" : "No";
            this.imageDialog = true;
        },
        deleteImageItem(item) {
            this.editedImageIndex = this.images.indexOf(item);
            this.editedImageItem = Object.assign({}, item);
            this.imageDialogDelete = true;
        },
        deleteImageItemConfirm() {
            this.deleteImage(this.editedImageItem.id);
            this.closeImageDelete();
        },
        closeImage() {
            this.imageDialog = false;
            this.editedImageItem = Object.assign({}, this.defaultImageItem);
            this.selectedImage = null;
            this.$v.$reset();
            this.error = "";
        },
        closeImageDelete() {
            this.imageDialogDelete = false;
        },
        saveImage() {
            this.editedImageIndex == -1 ? this.addImage() : this.updateImage();
        },
        saveFolder() {
            this.editedFolderIndex == -1 ? this.addSubFolder() : this.updateSubFolder();
        },
        getImage() {
            let formData = new FormData();
            formData.append("img", this.editedImageItem.image_url);
            formData.append('source', 'template');

            this.$Axios({
                method: 'post',
                url: '/api/v1/util/upload-image',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(({ data }) => {
                    this.selectedImage = data.img;
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        getImages() {
            this.loading = true;
            this.$Axios
                .get("/api/v1/image/type", {
                    params: {
                        image_type: "template"
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        this.images = res.data;
                        // in all images add seconds to the date
                        this.images.forEach((el) => {
                            el.date = el.date.slice(0, 19);
                        });
                        // make the first letter of the image type upper case
                        this.images.forEach((el) => {
                            el.image_type = el.image_type.charAt(0).toUpperCase() + el.image_type.slice(1);
                        });


                    } else {
                        alert(res.data["msg"]);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addImage() {
            console.log(this.editedImageItem);
            this.error = "";
            this.$v.$touch();
            console.log(this.$v);
            if (this.$v.$error) {
                alert("Please fill all the required fields")
                return;
            }
            let data = {};
            data.image_url = this.selectedImage;
            data.feature_type = "template";
            data.category = this.editedImageItem.category;
            data.language = this.editedImageItem.language;
            data.name = this.editedImageItem.name;
            data.logo_position = this.editedImageItem.logo_position;
            data.profile_visible = this.editedImageItem.profile_visible;
            if (this.editedImageItem.profile_visible == "Yes")
                data.profile_visible_location = this.editedImageItem.profile_visible_location;
            data.status = this.editedImageItem.status == "Active" ? "true" : "false";
            data.image_type = this.editedImageItem.image_type.toLowerCase();
            data.date = this.editedImageItem.date + ":00";
            data.show_protocol = this.editedImageItem.show_protocol;
            data.premium = this.editedImageItem.premium;
            if (this.editedImageItem.image_type == "Political") {
                // if the political_party option selected is "Select All" then send all the political parties
                if (this.editedImageItem.political_party.includes("Select All")) {
                    data.political_party = this.politicals.map((el) => {
                        return el.value;
                    });
                    data.political_party.shift();
                } else {
                    data.political_party = this.editedImageItem.political_party;
                }
            }
            // if the state option selected is "Select All" then send all the states
            if (this.editedImageItem.state.includes("Select All")) {
                data.state = this.states.map((el) => {
                    return el.value;
                });
                // remove the "Select All" option from the array
                data.state.shift();

            } else {
                data.state = this.editedImageItem.state;
            }

            console.log(`adding item : ${this.editedImageItem.id}`, data);

            this.$Axios
                .post("/api/v1/image/", data)
                .then(() => {
                    // reset form
                    this.editedImageIndex = -1;
                    this.editedImageItem = Object.assign({}, this.defaultImageItem);
                    this.selectedImage = null;
                    this.getImages();
                    this.closeImage();
                })
                .catch((e) => {
                    console.log(e);

                });
        },
        updateImage() {
            console.log(this.editedImageItem);
            this.error = "";
            this.editedImageItem.image_url = this.selectedImage;
            this.$v.$touch();

            console.log(this.$v);
            if (this.$v.$error) {
                return;
            }
            let updateData = {};
            if (this.editedImageItem.name) updateData.name = this.editedImageItem.name;
            if (this.editedImageItem.image_url)
                updateData.image_url = this.selectedImage;
            if (this.editedImageItem.logo_position)
                updateData.logo_position = this.editedImageItem.logo_position;
            if (this.editedImageItem.profile_visible_location)
                updateData.profile_visible_location = this.editedImageItem.profile_visible_location;

            if (this.editedImageItem.profile_visible == "No") {
                updateData.profile_visible_location = "";
                updateData.profile_visible = this.editedImageItem.profile_visible;
            } else {
                updateData.profile_visible_location = this.editedImageItem.profile_visible_location;
                updateData.profile_visible = this.editedImageItem.profile_visible;
            }

            if (this.editedImageItem.status)
                updateData.status = this.editedImageItem.status == "Active" ? "true" : "false";
            updateData.image_type = this.editedImageItem.image_type.toLowerCase();

            updateData.category = this.editedImageItem.category;
            updateData.language = this.editedImageItem.language;
            // change date format to  yyyy-mm-ddThh:mm:ss from yyyy-mm-dd
            // updateData.date = this.editedImageItem.date + "T00:00:00";
            updateData.date = this.editedImageItem.date + ":00";
            updateData.premium = this.editedImageItem.premium;
            updateData.show_protocol = this.editedImageItem.show_protocol;

            if (this.editedImageItem.image_type == "Political") {
                // if the political_party option selected is "Select All" then send all the political parties
                if (this.editedImageItem.political_party.includes("Select All")) {
                    updateData.political_party = this.politicals.map((el) => {
                        return el.value;
                    });
                    updateData.political_party.shift();
                } else {
                    updateData.political_party = this.editedImageItem.political_party;
                }
            }
            if (this.editedImageItem.state.includes("Select All")) {
                updateData.state = this.states.map((el) => {
                    return el.value;
                });
                // remove the "Select All" option from the array
                updateData.state.shift();
            } else {
                updateData.state = this.editedImageItem.state;
            }

            console.log(`updating item : ${this.editedImageItem.id}`, updateData);

            this.$Axios({
                method: "put",
                url: "/api/v1/image/" + this.editedImageItem.id + "/",
                data: updateData,
            })
                .then(() => {
                    this.editedImageItem = Object.assign({}, this.defaultImageItem);
                    this.selectedImage = null;

                    this.getImages();
                    this.closeImage();
                })
                .catch((e) => {
                    this.editedImageItem.image_url = null;
                    console.log(e.response);
                });
        },
        deleteImage(id) {
            this.$Axios
                .delete("/api/v1/image/" + id)
                .then(() => {
                    this.getImages();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getAllPoliticals() {
            this.$Axios
                .get('/api/v1/political/')
                .then((res) => {
                    this.politicals = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })
                    // add select all option
                    this.politicals.unshift({ value: "Select All", text: "Select All" });
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getAllStates() {
            this.$Axios
                .get('/api/v1/state/')
                .then((res) => {
                    this.states = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })
                    // add select all option    
                    this.states.unshift({ value: "Select All", text: "Select All" });
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    },
    computed: {
        imageFormTitle() {
            return this.editedImageIndex === -1 ? "New Template Image" : "Edit Template Image";
        },
        imageFormIcon() {
            return this.editedImageIndex === -1 ? "mdi-plus" : "mdi-edit";
        },
        filtredItems() {
            if (this.filter)
                return this.desserts.filter(
                    (item) =>
                        item.name.includes(this.filter) ||
                        item.license.includes(this.filter) ||
                        item.email.includes(this.filter) ||
                        item.db.includes(this.filter)
                );
            else return this.desserts;
        },
        //// Vuelidate Errors
        img_urlErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.image_url.$dirty) return errors;
            !this.$v.editedImageItem.image_url.required &&
                errors.push("Daily Image is required !");
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.name.$dirty) return errors;
            !this.$v.editedImageItem.name.required && errors.push("Name is required !");
            return errors;
        },
        logo_positionErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.logo_position.$dirty) return errors;
            !this.$v.editedImageItem.logo_position.required &&
                errors.push("Logo position is required");
            return errors;
        },
        dp_visibilityErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.profile_visible.$dirty) return errors;
            !this.$v.editedImageItem.profile_visible.required &&
                errors.push("Is DP Visible is required");
            return errors;
        },
        dp_positionErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.profile_visible_location.$dirty) return errors;
            if (this.editedImageItem.profile_visible_location == "Bottom-Left" || this.editedImageItem.profile_visible_location == "Bottom-Right") {
                return [];
            }
            !this.$v.editedImageItem.profile_visible_location.required && errors.push("Position is required");
            return errors;
        },
        statusErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.status.$dirty) return errors;
            !this.$v.editedImageItem.status.required && errors.push("Status is required");
            return errors;
        },
        typeErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.image_type.$dirty) return errors;
            !this.$v.editedImageItem.image_type.required && errors.push("Type is required");
            return errors;
        },
        folderNameErrors() {
            const errors = [];
            if (!this.$v.editedImageItem.name.$dirty) return errors;
            !this.$v.editedImageItem.name.required && errors.push("Name is required !");
            return errors;
        },
    },
    watch: {
        imageDialog(val) {
            val || this.close();
        },
        folderDialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    validations: {
        editedImageItem: {
            image_url: {
                required,
            },
            name: {
                required,
            },
            logo_position: {
                required,
            },
            profile_visible_location: {
                required: requiredIf(function () {
                    return this.editedImageItem.profile_visible == "Yes";
                }),
            },
            profile_visible: {
                required,
            },
            status: {
                required,
            },
            image_type: {
                required,
            },
        },
    },
};
</script>
<style scoped>
button.delete_folder {
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 9;
    background: #fff;
    border-radius: 50%;
    padding: 7px;
    box-shadow: 0 0 8px 2px #ccc;
}

button.edit_folder {
    position: absolute;
    top: 75px;
    right: 10px;
    z-index: 9;
    background: #fff;
    border-radius: 50%;
    padding: 7px;
    box-shadow: 0 0 8px 2px #ccc;
}
</style>

<style scoped>
button.delete_folder {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9;
    background: #fff;
    border-radius: 50%;
    padding: 7px;
    box-shadow: 0 0 8px 2px #ccc;
}
</style>
